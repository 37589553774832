
/***************/
/* MOBILE MENU */
/***************/

/* IPAD PRO */  

@media only screen and (max-width: 1024px) { 

    .photoSet {
      margin: 100px 0 0 0;
    }
    
    .photoSet .title {
      color: #333;
      font-size: 18px;
      margin: 0 0 20px 0px;
    }

    .photoSet .attachedImages {
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .photoGallery {
      margin: 100px 0 0 0;
    }

    .photoGallery .attachedImages .attachedImage div img {
      max-height: calc(100vh - 180px) !important;
      max-width: calc(100vw - 60px) !important;
    }

    .photoGallery .attachedImages .attachedImage.hiddenTitle div img {
      max-height: calc(100vh - 130px) !important;
    }

    .photoGallery .arrow.left {
      padding: 20px 10px 20px 10px;
    }

    .photoGallery .arrow.right {
      padding: 20px 30px 20px 20px;
    }

    .logo {
      position: absolute;
      
    }

    .home nav.menuTop ul li a {
      text-shadow: none;
    }
  
    .btnHamburger {
      display: flex;
      position: fixed;
      align-items: flex-end;
      justify-content: center;
      font-size: 28px;
      right: calc(var(--wrapper-padding)*0.5);
      top: 28px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      text-align: center;
      transition: 0s;
      line-height: 1;
      z-index: 30000;
      opacity: 0;
      animation: fade-in forwards 0.5s ease-in-out;
    }
  
    nav.menuTop ul {
      right: 0px !important;
      top: 0px !important;
      background: rgba(255,255,255,1);
      overflow-y: scroll;
      height: 0px;
    }
  
    nav.menuTop ul.menuList {
      padding: 30px 0px 0px 0px;
      opacity: 0;
      width: auto;
      transition: 0.5s;
    }

    nav.menuTop ul li:nth-last-child(1) a{
      padding-bottom: 50px;
    }
  
    nav.menuTop ul li {
      display: block;
      max-width: 300px;
      margin-left: 0px;
      text-align: left;
      padding: 0px 0 0px 0;
    }
  
    nav.menuTop ul li a {
      list-style: disc;
      font-size: 14px;
      line-height: 2;
      width: auto !important;
      text-align: left;
      color: #333 !important;
    }

    nav.menuTop ul li:nth-last-child(1) a{
      margin-bottom: 60px;
    }

    nav.menuTop ul li div.langSel {
      list-style: disc;
      font-size: 28px;
      padding: 12px;
      text-shadow: none !important;
      width: auto !important;
      text-align: left;
      border: none;
      color: #333 !important;
    }
  
    nav.menuTop ul li a.active {
      display: inline-block;
      font-size: 28px !important;
      background: #333;
      border-radius: 10px;
      padding: 16px;
      color: #fff !important;
      border-bottom: 0px;
    }

    nav.menuTop ul li.logoTop {
      display: block !important;
    }

  }

/* IPAD */  

@media only screen and (max-width: 768px) {

    .photoGallery .attachedImages .attachedImage div img {
      max-height: calc(100vh - 280px) !important;
    }

    .photoGallery .attachedImages .attachedImage.hiddenTitle div img {
      max-height: calc(100vh - 230px) !important;
    }
    
}

/* IPHONE */

@media only screen and (max-width: 767px) { 

    .photoSet {
        margin: 100px 0 0 0;
    }

    .photoSet .title {
      color: #333;
      font-size: 18px;
      margin: 0 0 20px 0px;
    }
    .photoSet .attachedImages {
      flex-direction: column;
    }

    .photoSet .attachedImages .attachedImage {
        width: auto;
        height: auto;
        margin: 30px 0 30px 0px;
    }

    .photoSet .attachedImages .attachedImage div {
      max-width: 300px;
      max-height: 300px;
    }
    
    .photoSet .attachedImages .attachedImage div img {
      position: relative;
      display: block;
      background-color: #fafafa;
      transition: 0.3s;
      width: auto;
      height: auto;
      max-width: 300px;
      max-height: 300px;
    }

    .photoSet .content {
      max-width: 100%;
    }

    .photoSet .content a {
      white-space: wrap;
    }

    .photoSet .content p iframe {
      
      max-width: 300px;
      max-height: 200px;
    }

    .photoSet .content p iframe body {
      text-align: left;
    }

    .photoSet .content img.alignright {
      float: none;
      width: 100%;
      padding: 0 0 20px 00px;
      height: auto;
    }

    .CookieConsent button {
      margin: 12px 0px 0px 0px !important;
    }
}

/* IPAD LANDSCAPE */

@media only screen and (max-width: 1024px) { 
  @media only screen and (max-height: 768px) { 
    .photoGallery .attachedImages .attachedImage div img {
      max-height: calc(100vh - 275px) !important;
    }

    .photoGallery .attachedImages .attachedImage.hiddenTitle div img {
      max-height: calc(100vh - 225px) !important;
    }
  }
}