:root {
  --vh: 100vh;
  --vw: 100vw;
  --limit: 1024px;
  --wrapper-width: 100vw;
  --content-width: 1075px;
  --wrapper-padding: 30px;
  --block-padding: 30px;
  --font-color: #231f20;
}

@import url('https://fonts.googleapis.com/css2?family=Domine&display=swap');

html {
  scroll-behavior: smooth;
}

.App {
  text-align: left;
  color: #231f20;
  font-size: 12px;
  color: var(--font-color);
  font-family: "Domine", serif;
  font-weight: normal !important;
  opacity: 1;
}

a {
  color: #231f20;
  text-decoration: none;
}

a:hover {
  color: #231f20;
  text-decoration: none;
}

.anchor {
  white-space: normal;
}

.wrapper {
  color: #231f20;
  max-width: var(--wrapper-width);
  padding: var(--wrapper-padding);
  margin-bottom: 0;
}

#preload {
  display: none;
}

.home .wrapper {
  position: relative;
  color: #fff;
  padding: 0px ;
  /*height: calc(var(--vh)/1);*/
  overflow: hidden;
  background-color: #fff;
}

.tint {
  position: relative;
  background-size: cover;
  background-position: center center;
  max-width: var(--wrapper-width);
  /*min-height: calc(var(--vh) );*/
  margin: 0 auto auto auto;
  opacity: 0;
  animation: fade-in-blur forwards 1.5s ease-in-out;
  z-index: 1;
}

.wrapperSqueeze {
  max-width: 1075px;
  /*min-height: calc(100vh - 96px);*/
  margin-top: -27px;
  padding: 0 var(--wrapper-padding) 0 250px;
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0.5s;
}

/********/
/* MENU */
/********/

nav.menuTop ul {
  position: fixed;
  top: 100px;
  left: 30px;
  margin: 0px;
  list-style: none;
  text-align: left;
  z-index: 100;
  padding: 0px;
}

nav.menuTop ul li {
  display: block;
  opacity: 0;
  line-height: 1.65;
}

nav.menuTop ul li.logoTop {
  display: none;
}

nav.menuTop ul li.logoTop a {
  font-size: 22px;
  padding-right: 0;
  color: #231f20;
  transition: 0.3s;
  line-height: 1.2;
}

nav.menuTop ul li a{
  display: block;
  color: #000;
  font-size: 12px;
  font-style: italic;
  transition: 0.3s;
  border-radius: 10px;
  transition: ease-in-out 0.3s;
}


nav.menuTop ul li a::before {
  content: "- ";
}

nav.menuTop ul li:nth-child(1) a,  nav.menuTop ul li:nth-child(2) a, nav.menuTop ul li:nth-child(3) a, nav.menuTop ul li:nth-last-child(1) a, nav.menuTop ul li:nth-last-child(2) a {
  font-style: normal;
  padding-bottom: 15px;
}

nav.menuTop ul li:nth-last-child(2) a{
  margin-top: 15px;
}

nav.menuTop ul li:nth-child(1) a:before, nav.menuTop ul li:nth-child(2) a:before, nav.menuTop ul li:nth-child(3) a:before, nav.menuTop ul li:nth-last-child(1) a:before, nav.menuTop ul li:nth-last-child(2) a:before {
  content: "";
}

nav.menuTop ul li a.active {
  color: #231f20;
}

nav.menuTop ul li a:hover {
  color: #231f20;
}

nav.menuTop ul li {
  animation: fade-in forwards 0.6s ease-in-out;
  animation-delay: 0s;
}

nav.menuTop .langSel {
  cursor: pointer;
}

.btnHamburger {
  cursor: pointer;
  display: none;
}

.home .btnHamburger {
  color: #231f20;
}

.logo {
  font-size: 16px;
  position: fixed;
  top: 30px;
  left: var(--wrapper-padding);
  opacity: 0;
  animation: fade-in forwards 0.5s ease-in-out;
  animation-delay: 0.2s;
  z-index: 100;
}

.logo a {
  font-size: 22px;
  padding-right: 12px;
  color: #231f20;
  transition: 0.3s;
  line-height: 1.2;
}

/***********/
/* GENERAL */
/***********/

.anchor {
  padding: 0;
  font-size: 18px;
  line-height: 26px !important;
  font-weight: normal;
  text-align: left;
  width: auto;
  color: #231f20;
  user-select: none;
}

blockquote {
  border-left: 6px solid #eee;
  margin: 0px;
  padding: 3px 0 6px 24px;
}

.spacer {
  width: 0px;
  height: calc(var(--wrapper-padding)*1);
}

.spacerHalf {
  height: calc(var(--wrapper-padding) /2);
}

.sectionTitle {
  display: flexbox;
  position: relative;
  max-width: var(--content-width);
  margin-bottom:  auto;
}

.padLow {
  margin-bottom: 60px;
}

/*****************/
/* ABOUT SECTION */
/*****************/

.about {
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0;
}

.home .about {
  padding:  calc(var(--block-padding)*1.6) 0 calc(var(--block-padding)*1) 0;
}

.about .anchor {
  padding-right: 30px;
}

.about h1 {
  color: #64434E;
  font-size: 36px;
  line-height: 54px;
  font-weight: 700;
}

.about .readMore  {
  position: absolute;
  transform: translate(0px, -86px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: 600;
  background-color: #7E5563;
  color: #fff;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.about a.readMore:hover {
  background-color: #231f20;
  color: #fff;
}

.about p.end {
  transform: translate(0px, -96px);
  height: 70px;
  background: linear-gradient(to top, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.6) 80%,rgba(255,255,255,0) 100%); 
}

.about .authorBubble {
  margin-top: 28px !important;
}

/*******************/
/* BLOCK 2 COLUMNS */
/*******************/

.blockFull {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-direction: column;
  max-width: 100%;
}

.blockLeft {
  display: flexbox;
  position: relative;
  padding: 0px 0px var(--block-padding) 0px;
  max-width: 500px;
  white-space: nowrap;
}

.blockLeft .authorBubble {
  padding-top: 7px;
}

.blockText {
  display: flexbox;
  margin-top: 9px;
  max-width: 100%;
  padding: 10px 0 0 0;
  border-left: solid 0px #eee;
  font-size: 21px;
  line-height: 36px;
  color: #231f20;
}

.blockText p {
  display: block;
  max-width: 520px;
}

.blockText p a {
  color: #231f20;
  border-bottom: solid 1px #231f20;
  transition: ease-in-out 0.3s;
}

.blockText p a:hover {
  color: #64434E;
  border-bottom: solid 1px #64434E;
}

.blockText h2 {
  color: #231f20;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  margin: 0px;
}

.blockText li {
  padding: 0 0 14px 0;
}

.blockText p:first-child {
  margin-top: 0px;
}

.blockText p img {
  width: 100%;
}

.blockText .image .loading {
  position: relative;
  width: 100%;
  height: 320px;
  background-color: #f5f5f5;
  margin: 10px 0 36px 0;
}

.blockLeft .image .loading {
  position: relative;
  width: 510px;
  height: 590px;
  background-color: #f5f5f5;
  margin: 48px 48px 48px 0;
}



/***************/
/* POST OBJECT */
/***************/

.posts {
  max-width: var(--content-width);
  margin: 0px 0 calc(var(--block-padding)*2) 0;
}

.post {
  font-size: 21px;
  line-height: 36px;
  color: #231f20;
}

.post h1 {
  color: #231f20;
  /*color: #544c57;*/
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
  margin: 0 0 0 0;
  transition: ease-in-out 0.3s;
}

.post h2 {
  color: #231f20;
  font-size: 36px;
  line-height: 39px;
  font-weight: 600;
  font-weight: 700;
  margin: calc(var(--block-padding) *1.2) 0 0px 0;
}

.post h3 {
  color: #544c57;
  font-size: 26px;
  font-weight: 600;
  font-weight: 700;
  margin: 36px 0 30px 0;
}

.post .excerpt {
  color: #999;
  padding-top: 0px;
  font-size: 21px;
  line-height: 28px;
  margin: 0px 0 0px 0px;
}

.posts img.banner {
  max-width: 100%;
}

.btnBack {
  position: absolute;
  transform: translate(0px, -40px);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0px;
  line-height: 1;
  font-size: 18px;
  color: #777;
  border-radius: 100%;
}

/*************/
/* PHOTO SET */
/*************/

.photoSet {
  margin: 4px 0 0 220px;
}

.photoSet .title {
  color: #231f20;
  font-size: 18px;
  margin-bottom: 20px;
}

.photoSet .content {
  font-size: 12px;
  line-height: 1.7;
  margin-bottom: 20px;
  max-width: 720px;
}

.photoSet .content p:first-child {
  margin-top: 6px !important;
}

.photoSet .content p iframe {
  width: 640px !important;
}

.photoSet .content img.alignright {
  float: right;
  width: 350px;
  padding: 0 0 20px 30px;
  height: auto;
}

.photoSet .content a {
  color: #2430b3;
}

.photoSet .thinSpacer {
  height: 4px;
}

.photoSet .attachedImages {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  max-width: 1250px;
}

.photoSet .attachedImages .attachedImage {
  position: relative;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 206px;
  height: 206px;
}

.photoSet .attachedImages .attachedImage div {
  position: relative;
  width: auto;
  height: auto;
  max-width: 180px;
  max-height: 180px;
  background-color: #fafafa;
}

.photoSet .attachedImages .attachedImage div img {
  position: relative;
  display: block;
  background-color: #fafafa;
  transition: 0.3s;
  width: auto;
  height: auto;
  max-width: 180px;
  max-height: 180px;
}

.photoSet .attachedImages .attachedImage a div img:hover {
  opacity: 0.8;
}

.photoSet .attachedImages .attachedImage span {
  display: block;
  position: relative;
  z-index: 100;
  opacity: 1;
}

.photoSet .attachedImages span img {
  margin-top: 12px;
  background-color: #231f20;
  border-radius: 100%;
  padding: 4px;
  width: 20px;
}


/*************/
/* PHOTO GALLERY */
/*************/

.photoGallery {
  margin: 4px 0 0 220px;
}

.photoGallery .title {
  display: inline-block;
  color: #231f20;
  font-size: 18px;
  margin-bottom: 34px;
}

.photoGallery .thinSpacer {
  height: 4px;
}

.photoGallery .attachedImages {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.photoGallery .attachedImages .navigation {
  position: relative;
  color: #aaa;
  z-index: 1000;
}

.photoGallery .attachedImages .navigation .arrow  {
  display: inline;
  color: #aaa;
  font-size: 12px !important;
  cursor: pointer;
  transition: 0.3s;
}

.photoGallery .attachedImages .navigation .arrow:hover  {
  color: #231f20;
}

.photoGallery .attachedImages .navigation a.text  {
  font-size: 10px !important;
  color: #aaa;
}


.photoGallery .attachedImages .preload {
  position: absolute;
  opacity: 0;
  left: -2000px;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.photoGallery .attachedImages .attachedImage {
  position: relative;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  opacity: 0;
  display: none;
  overflow: hidden;
}

.photoGallery .attachedImages .spinner {
  position: absolute;
  top: 40px !important;
}

.photoGallery .attachedImages .attachedImage div {
  position: relative;
}

.photoGallery .attachedImages .attachedImage div img {
  display: block;
  width: auto;
  height: auto;
  max-height: calc(100vh - 180px) !important;
  max-width: calc(100vw - 300px) !important;
  background-color: #fafafa;
  z-index: 100;
  transition: 0.1s;
}

.photoGallery .attachedImages .attachedImage.hiddenTitle div img {
  max-height: calc(100vh - 130px) !important;
}

.photoGallery .attachedImages .attachedImage div #myImage.load {
  opacity: 0.1;
}

.photoGallery .attachedImages .attachedImage.show {
  opacity: 1;
  height: auto !important;
  position: absolute;
  top: 0px;
  display: block;
  animation: fade-in forwards 0.6s ease-in-out;
} 


.photoGallery .attachedImages .attachedImage .details {
  z-index: 2000;
  display: flex;
  flex-direction: column;
  transition: 0.6s;
}

.photoGallery .attachedImages .attachedImage .details.load {
  transition: 0s;
  opacity: 0;
}

.photoGallery .attachedImages .attachedImage .details.fadeIn {
  animation: fade-in forwards 0.6s ease-in-out;
}

.photoGallery .attachedImages .attachedImage .details .imageTitle {
  color: #231f20;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 12px;
}

.photoGallery .attachedImages .attachedImage .details .imageCaption {
  font-size: 12px;
  color: #aaa;
  margin-top: 2px;
}

.photoGallery .attachedImages .attachedImage .loading  {
  position: relative;
  background-color: #f5f5f5;
}

.photoGallery .attachedImages .attachedImage .loading div {
  position: relative;
  background-position: top left !important;
  transition: 0.3s;
}

.photoGallery .attachedImages .attachedImage a .loading:hover {
  transform: scale(1.02);
}

.photoGallery .attachedImages .attachedImage span {
  display: block;
  position: relative;
  z-index: 100;
  opacity: 1;
}

.photoGallery .attachedImages span img {
  margin-top: 12px;
  background-color: #231f20;
  border-radius: 100%;
  padding: 4px;
  width: 20px;
}

/****************/
/* GRID OBJECTS */
/****************/

.grid {
  display: flex;
  align-items: top;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: var(--content-width);
}

.grid .post .content, .post .grid .end  {
  display: none;
}

.grid .post {
  display: flex;
  width: 520px;
  align-items: top;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.grid .post .image .box {
  text-align: right;
}

.grid .post .image .box .loading {
  position: relative;
  width: 270px;
  height: 270px;
  margin: 0 24px 20px 0 ;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.grid .post .image img {
  width: 100%;
  height: 270px !important;
  object-fit: cover;
  margin: 0 auto 0 0;
}

.grid .post h1 {
  font-size: 32px;
  line-height: 38px;
  margin: 0 0 0 0px;
}

.grid .post a.title h1:hover {
  color: #7E5563;
}

.grid .post .excerpt {
  color: #999;
  padding-top: 0px;
  font-style: italic;
  font-size: 18px;
  line-height: 22px;
  margin: 0px 0 0px 0px;
}

.grid .post .text {
  display: flexbox;
  position: relative;
  width: 100%;
  margin: 0 20px 0 0px;
}

.grid .post .readMore  {
  display: none;
  position: absolute;
  transform: translate(0px, -30px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  background-color: #eee;
  color: #231f20;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.grid .post a.readMore:hover {
  background-color: #64434E;
  color: #fff;
}

/****************/
/* LIST OBJECTS */
/****************/

.list  {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
}

.list .post  {
  display: flex;
  max-width: var(--content-width);
}

.list .post a.title h1 {
  color: #231f20;
}

.list .post a.title h1:hover {
  color: #64434E;
}

.list .post .text {
  display: flexbox;
  position: relative;
  width: 70%;
}

.list .post .text hr {
  padding: 20px 0 0px 0;
  border: none;
  text-align: left;
  color: #ccc;
}

.list .post .text hr::after {
  content: "***";
}

.list .post .text blockquote {
  border-left: 6px solid #eee;
  margin: 0px;
  padding: 3px 0 6px 24px;
  font-style: italic;
}


.list .post .text .wp-block-table {
  padding: 20px 0px 20px 0;
  margin: 0px;
}

.list .post .text .wp-block-table td {
  padding: 14px;
  background-color: #eee;
  font-size: 18px;
}

.list .post .text p.end {
  transform: translate(0px, -120px);
  height: 100px;
  background: linear-gradient(to top, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 80%); 
}

.list .post .text a.readMore {
  position: absolute;
  transform: translate(0px, -100px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  background-color: #eee;
  color: #231f20;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.list .post .text a.readMore:hover {
  background-color: #64434E;
  color: #fff;
}

.list .post .image .box .loading {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.list .post .image {
  display: flexbox;
  border: none;
  color: #7E5563;
  padding: 32px 0 0 60px;
  margin: 0px;
  text-align: right;
  width: 50%;
}

.list .post .image .box {
  width: 100%;
  margin: 0px 0 0px 0;
  line-height: 1;
}

.post .box img {
  width: 100%;
  transition: 0.3s ease-in-out;
}

.post .box a .loading:hover {
  transform: scale(1.02);
}

.list .post .image .details a {
  opacity: 0.3;
  font-size: 12px;
  color: #544c57;
  transition: 0.3s ease-in-out;
}

.list .post .image .details a:hover {
  color: #544c57;
  opacity: 1;
}

.list .post .image .details .link {
  transform: translate(-3px, 6px);
  width: 20px;
  color: #7E5563;
}


/*******************/
/* CONTACT PAGE */
/*******************/

.contact {
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*2.6) 0;
  font-size: 21px;
  line-height: 36px;
}

.contact p a {
  text-decoration: none !important;
  border-bottom: 2px solid #231f20;
}

.contact .blockText {
  margin-top: 102px;
}

/*******************/
/* PRIVACY PAGE */
/*******************/

.privacy {
  padding: calc(var(--block-padding)*2) 0 calc(var(--block-padding)*2) 0;
  font-size: 14px;
  line-height: 24px;
}

.privacy h2 {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.privacy .content {
  max-width: 60%;
}

.privacy p u, .privacy p a {
  text-decoration: none !important;
  border-bottom: 1px solid #231f20;
}

/**********/
/* FOOTER */
/**********/

.footer {
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1s;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--wrapper-padding);
  max-width: var(--content-width) !important;
  font-size: 12px;
  color: #231f20;
}


.footer .links a {
  color: #231f20;
  transition: 0.3s;
}

.footer .links a:hover {
  color: #777;
}


/******************/
/* COOKIE CONSENT */
/******************/

.CookieConsent {
  background: rgba(255,255,255,1) !important;
  z-index: 20000 !important;
  text-align: left;
  padding: 20px 0 18px 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
  bottom: 0px !important;
}

.CookieConsent div {
  margin: 0 var(--wrapper-padding) 0 var(--wrapper-padding) !important;
  font-size: 12px;
  color: #231f20;
}

.CookieConsent a {
  color: #231f20;
  margin-left: 0px;
  padding-bottom: 1px;
  border-bottom: solid 1px #231f20;
  transition: ease-in-out 0.3s;
}

.CookieConsent a:hover {
  color: #777;
  border-bottom: solid 1px #777;
}

.CookieConsent button {
  color: #fff !important;
  font-weight: 400;
  background: #231f20 !important;
  font-size: 12px;
  border-radius: 4px !important; 
  cursor: pointer;
  padding: 12px !important;
  transition: ease-in-out 0.3s;
  border: solid 4px transparent !important;
}

.CookieConsent button:hover {
  background: #777 !important;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.0) !important;
  border: solid 4px #777 !important;
}

/*******************/
/* LOADING SPINNER */
/*******************/

.spinner {
  width: 50px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

.loader {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc(50% - 2em);
}

.loader .spinner > div {
  background-color: rgba(0,0,0,0.6);
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

/**************/
/* ANIMATIONS */
/**************/

@keyframes fade-in{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fade-out{
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-blur {
  0% {
    opacity: 0;
    filter: blur(2px);
  }
  100% {
    ilter: blur(0px);
    opacity: 100;
  }
}

@keyframes fade-in-slide {
  0% {
    opacity: 0;
    transform: translate(0px, 80px);
  }
  90% {
    opacity: 100;
    transform: translate(0px, 1px);
  }
  100% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}


@keyframes fade-out-slide {
  0% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
  90% {
    opacity: 0;
    transform: translate(0px, 29px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 30px);
  }
}

@keyframes fade-in-slide-down {
  from {
    opacity: 0;
    transform: translate(0px, -50px);
  }
  to {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}

@keyframes fade-in-slide-right {
  0% {
    opacity: 0;
    transform: translate(-20px, 0px);
  }
  80% {
    
  }

  100% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}